<!--
import Axios from 'axios';
import Axios from 'axios';
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-07 16:46:29
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="saleb">
        <div class="tit">
            <p>销售数据管理</p>
            <div @click="caozuo_rzhi">操作日志<i></i></div>
        </div>
        <div class="con_tit">
            <span :class="index==con_tit_num?'span1':''" @click="con_tit_dianji(index)" v-for="(i,index) in con_tit_list" :key="index">{{i}}</span>
        </div>
        <div class="left_zhu" v-if="con_tit_num==1">
            时间:
            <el-date-picker
                @change="rqi_huanle"
                style="border:none;"
                v-model="value1" 
                type="daterange"
                :clearable='false'
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="结束时间">
            </el-date-picker>
        </div>
        <div class="con" v-if="con_tit_num==0">
            <ul class="ul1">
                <li class="li1">
                    <span>1</span>下载销售模块表格模版<p @click="moban_xiazai">下载</p>
                </li>
            </ul>
            <ul class="ul2">
                <li class="li2"><input ref="quanbu" type="checkbox" @click="dianji_quanxian_quanbu">全部</li>
                <li class="li2" v-for="(i,index) in moban_list" :key="index"><input ref="quana" type="checkbox" @click="dianji_quanxian(index)">{{i.name}}</li>
            </ul>
            <ul class="ul1" style="margin-bottom:0.84rem;">
                <li class="li1">
                    <span>2</span>上传填写好销售模块表
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=''
                        :file-list="fileList"
                        :multiple="false"
                        :auto-upload="false">
                        <el-button style="background: #8d52e3;border:none;margin-left:0.15rem;margin-right:0.3rem;" slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </li>
            </ul>
        </div>
        <div class="box" v-if="con_tit_num==1">
            <ul class="ul1">
                <li class="li1">
                    <span>1</span>数据成果表格 <p style="display: flex;align-items: center;"><img src="../../assets/shixin.png">&nbsp;&nbsp;销售发票合同回款表</p>
                </li>
            </ul>
            <ul class="ul1" style="margin-bottom:0.45rem;margin-top:0.41rem;">
                <li class="li1">
                    <span>2</span>请选择需要下载的表格类型(可多选)
                </li>
                <ul class="ul2">
                    <!-- <li class="li2"><input ref="daochu_quanbu" type="checkbox" @click="dianji_daochu_quanbu">全部</li> -->
                    <li class="li2" v-for="(i,index) in daochu_list" :key='index'  @click="dianji_mingxi_huizong(index)">
                        <!-- <input ref="daochu_quana" type="checkbox" @click="dianji_daochu_danxuan(index)"> -->
                        <img :src="index==mingxi_huizong_num?require('../../assets/shixin.png'):require('../../assets/kongxin.png')">
                        {{i.name}}
                    </li>
                </ul>
                <transition name="fade">
                    <ul class="ul3" v-if="mingxi_huizong_num==1">
                        <li v-for="(i,index) in xuanze_xiangxi_list" :key="index">
                            <!-- <img :src="index==xuanze_xiangxi_num?require('../../assets/kuang_duigou.png'):require('../../assets/kaung_kongxin.png')" alt=""> -->
                            <input ref="daochu_xuanze" type="checkbox" @click="dian_xuanze_xiangxi(i,$event.path[0].checked)">
                            {{i.name}}
                        </li>
                    </ul>
                </transition>
            </ul>
            <!-- <ul class="ul1">
                <li class="li1">
                    <span>3</span>数据成果表格 <p style="display: flex;align-items: center;"><img src="../../assets/shixin.png">&nbsp;&nbsp;销售发票合同回款表</p>
                </li>
                <p class="p1">请选择需要下载的表格类型(可多选)</p>
                <ul class="ul2">
                    <li class="li2"><input ref="daochu_quanbu" type="checkbox" @click="dianji_daochu_quanbu">全部</li>
                    <li class="li2" v-for="(i,index) in daochu_list" :key='index'><input ref="daochu_quana" type="checkbox" @click="dianji_daochu_danxuan(index)">{{i.name}}</li>
                </ul>
                <p class="p2">导出</p>
            </ul> -->
        </div>
        <p style="margin:0.11rem 0.3rem;font-size:0.12rem;color:#A9A9A9;line-height:0.2rem;">法律声明：字段{{con_tit_list[con_tit_num]}}服务由CBR研发并经授权企业管理员选择开通、使用、管理，相关数据存储于CBR专属云存储空间并归属企业所有。CBR安全技术已通过相关机构认证，请放心使用。</p>
        <p class="shangchuan_btn"  v-if="con_tit_num==0" @click="shangchuan">上传</p>
        <p class="shangchuan_btn"  v-if="con_tit_num==1" @click="daochu">导出</p>
        <div style="height:0.3rem;"></div>
    </div>
</template>

<script>
export default {
  name: 'saleb',
  data () {
    return {
      lujing: [],
      moban_list: [
        {
          name: '合同',
          lujing: 'https://www.mlcbr.com/template/合同表模板.xlsx'
        },
        {
          name: '发票',
          lujing: 'https://www.mlcbr.com/template/发票表模板.xlsx'
        },
        {
          name: '回款',
          lujing: 'https://www.mlcbr.com/template/回款表模板.xlsx'
        },
        {
          name: '漏斗表',
          lujing: 'https://www.mlcbr.com/template/漏斗表模板.xlsx'
        }
      ],
      fileList: [],
      daochu_list: [
        {
          key: '2',
          name: '明细表'
        },
        {
          key: '3',
          name: '总汇表'
        }
      ],
      daochu_num: [],
      ent_id: '',
      con_tit_list: ['导入', '导出'],
      con_tit_num: '0',
      mingxi_huizong_num: '0',
      xuanze_xiangxi_list: [
        {
          name: '合同编号',
          index: '1'
        },
        {
          name: '合同日期',
          index: '2'
        },
        {
          name: '客户',
          index: '3'
        },
        {
          name: '省份',
          index: '4'
        },
        {
          name: '城市',
          index: '5'
        },
        {
          name: '行业',
          index: '6'
        },
        {
          name: '产品',
          index: '7'
        },
        {
          name: '部门',
          index: '8'
        },
        {
          name: '人员',
          index: '9'
        }
      ],
      xuanze_xiangxi_num: [],
      value1: '',
      start_sj: '',
      end_sj: '',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/'
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
    }
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
  },
  mounted () {
    this.jichu()
    this.jichu2() 
  },
  watch: {
    lujing () {
      if (this.lujing.length == 4) {
        this.$refs.quanbu.checked = true
      } else {
        this.$refs.quanbu.checked = false
      }
    }
    //   daochu_num(){
    //       if(this.daochu_num.length==2){
    //           this.$refs.daochu_quanbu.checked=true
    //       }else{
    //           this.$refs.daochu_quanbu.checked=false
    //       }
    //   }
  },
  methods: {
    //   基础信息
    jichu () {
      setTimeout(() => {
        this.lujing = []
        console.log(this.$refs.quanbu)
        this.$refs.quanbu.checked = true
        for (let i = 0; i < this.$refs.quana.length; i++) {
          this.$refs.quana[i].checked = true
        }
        for (let i = 0; i < this.moban_list.length; i++) {
          this.lujing.push(this.moban_list[i])
        }
      }, 0)
    },
    // 基础信息2
    jichu2 () {
      // console.log(this.$refs)
      // this.$refs.daochu_quanbu.checked=true
      // for(let i=0;i<this.$refs.daochu_quana.length;i++){
      //     this.$refs.daochu_quana[i].checked=true
      // }
      for (let i = 0; i < this.daochu_list.length; i++) {
        this.daochu_num.push(this.daochu_list[i])
      }
    },
    //   下载多个文件
    multiDownLoad (val) {
      for (let i = 0; i < val.length; i++) {	
        const iframe = document.createElement('iframe')
        iframe.style.display = 'none'
        iframe.style.height = 0
        iframe.src = val[i] 
        document.body.appendChild(iframe)
        setTimeout(res => {
          iframe.remove()
        }, 5 * 60 * 1000
        )
      }  
    },
    moban_xiazai () {
      if (this.lujing != []) {
        // if(this.lujing.length==4){
        //     this.multiDownLoad(['https://www.mlcbr.com/template/销售模板.xlsx'])
        // }else{
        const data = []
        for (let i = 0; i < this.lujing.length; i++) {
          data.push(this.lujing[i].lujing)
        }
        this.multiDownLoad(data)
        // }              
      } else {
        this.$message({
          message: '请选择要下载的模板',
          type: 'warning'
        }) 
      }
    },
    // 全选
    dianji_quanxian_quanbu () {
      if (this.$refs.quanbu.checked) {
        this.lujing = []
        this.jichu()
      } else {
        this.lujing = []
        for (let i = 0; i < this.$refs.quana.length; i++) {
          this.$refs.quana[i].checked = false
        }
      }
    },
    // 单个勾选
    dianji_quanxian (index) {
      if (this.$refs.quana[index].checked) {
        this.lujing.push(this.moban_list[index])
      } else {
        for (let i = 0; i < this.lujing.length; i++) {
          if (this.moban_list[index].name == this.lujing[i].name) {
            this.lujing.splice(i, 1)
          }
        }
      }
      console.log(this.lujing)
    },
    // 导出全选
    // dianji_daochu_quanbu(){
    //     if(this.$refs.daochu_quanbu.checked){
    //         this.daochu_num=[]
    //         this.jichu2()
    //     }else{
    //         this.daochu_num=[]
    //         for(let i=0;i<this.$refs.daochu_quana.length;i++){
    //             this.$refs.daochu_quana[i].checked=false
    //         }
    //     }
    // },
    // 导出单个勾选
    // dianji_daochu_danxuan(index){
    //     // if(this.$refs.daochu_quana[index].checked){
    //     //     this.daochu_num.push(this.daochu_list[index])
    //     // }else{
    //     //     for(let i=0;i<this.daochu_num.length;i++){
    //     //         if(this.daochu_list[index].name==this.daochu_num[i].name){
    //     //             this.daochu_num.splice(i,1)
    //     //         }
    //     //     }
    //     // }
    // },
    // 明细汇总
    dianji_mingxi_huizong (index) {
      this.mingxi_huizong_num = index + ''
    },
    // 上传
    shangchuan () {
      if (this.$refs.upload.uploadFiles.length != 0) {
        console.log(this.$refs.upload.uploadFiles)
        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])
          } else {
            const fileObj = list[i]
            const form = new FormData()// FormData 对象
            form.append('files', fileObj)// 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.ent_id)
            console.log(fileObj.name.split('.'))
            const name = fileObj.name.split('.')[0]
            sessionStorage.setItem('pc_mlbb_shangchuan_chenggong', fileObj.name)
            if (name.indexOf('合同') != -1) {
              $.ajax({
                url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_contract_ai',
                // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_contract_ai',
                // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/import_contract_ai',
                type: 'post',
                data: form,
                processData: false,
                contentType: false	
              }).then(res => {
                console.log(res)
                sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.body))
                if (res.code == 10181) {
                  this.$message({
                    message: '导入成功',
                    type: 'success'
                  })
                  this.$router.push('/feedbackpage')
                } else if (res.code == 10182) {
                  this.$message({
                    message: '导入失败，请重新导入',
                    type: 'error'
                  }) 
                }
              })
            } else if (name.indexOf('发票') != -1) {
              $.ajax({
                url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_invoice_ai',
                // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/import_invoice_ai',
                // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_invoice_ai',
                type: 'post',
                data: form,
                processData: false,
                contentType: false	
              }).then(res => {
                console.log(res)
                sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.body))
                if (res.code == 10183) {
                  this.$message({
                    message: '导入成功',
                    type: 'success'
                  })
                  this.$router.push('/feedbackpage')
                } else if (res.code == 10184) {
                  this.$message({
                    message: '导入失败，请重新导入',
                    type: 'error'
                  }) 
                }
              })
            } else if (name.indexOf('回款') != -1) {
              $.ajax({
                // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/import_rec_ai',
                url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_rec_ai',
                // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_rec_ai',
                type: 'post',
                data: form,
                processData: false,
                contentType: false	
              }).then(res => {
                console.log(res)
                sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.body))
                if (res.code == 10185) {
                  this.$message({
                    message: '导入成功',
                    type: 'success'
                  })
                  this.$router.push('/feedbackpage')
                } else if (res.code == 10186) {
                  this.$message({
                    message: '导入失败，请重新导入',
                    type: 'error'
                  }) 
                }
              })
            } else if (name.indexOf('漏斗') != -1) {
              $.ajax({
                // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/import_funn_ai',
                url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_funn_ai',
                // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/import_funn_ai',
                type: 'post',
                data: form,
                processData: false,
                contentType: false	
              }).then(res => {
                console.log(res)
                sessionStorage.setItem('pc_mlbb_shangchuan_chenggong_shuju', JSON.stringify(res.body))
                if (res.code == 10179) {
                  this.$message({
                    message: '导入成功',
                    type: 'success'
                  })
                  this.$router.push('/feedbackpage')
                } else if (res.code == 10180) {
                  this.$message({
                    message: '导入失败，请重新导入',
                    type: 'error'
                  }) 
                }
              })
            }
          }
        }
      } else {
        this.$message({
          message: '请选择要导入的文件',
          type: 'warning'
        }) 
      }
    },
    // 进入操作日志页面
    caozuo_rzhi () {
      sessionStorage.setItem('pc_mlbb_bumenrzhi', '销售')
      this.$router.push('/calendara')
    },
    // 选择导入还是到处
    con_tit_dianji (index) {
      this.con_tit_num = index
      if (index == 0) {
        this.jichu()
      }
    },
    // 导出接口
    daochu () {
      if (this.start_sj != '' && this.start_sj != '') {
        if (this.mingxi_huizong_num == 0) {
          const url = this.url + 'export_sale_detail?ent_id=' + this.$ent_id() + '&excel_type=2' + '&date_begin=' + this.start_sj + '&date_end=' + this.end_sj
          const data = [url]
          this.multiDownLoad(data)
        } else if (this.mingxi_huizong_num == 1) {
          for (let i = 0; i < this.xuanze_xiangxi_num.length; i++) {
            const url = this.url + 'export_sale_gather?ent_id=' + this.$ent_id() + '&excel_type=2' + '&date_begin=' + this.start_sj + '&date_end=' + this.end_sj + '&gather_type' + this.xuanze_xiangxi_num[i]
            const data = [url]
            this.multiDownLoad(data)
          }
        }
      } else {
        this.$message({
          message: '请选择导出的时间段',
          type: 'warning'
        }) 
      }
    },
    // 点详细
    dian_xuanze_xiangxi (data, e) {
      // this.xuanze_xiangxi_num=index
      // xuanze_xiangxi_num
      console.log(e)
      if (e) {
        this.xuanze_xiangxi_num.push(data.index)
      } else {
        for (let i = 0; i < this.xuanze_xiangxi_num.length; i++) {
          if (data.index == this.xuanze_xiangxi_num[i]) {
            this.xuanze_xiangxi_num.splice(i, 1)
          }
        }
        console.log(this.xuanze_xiangxi_num)
      }
    },
    // 时间小于10前面加0
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    // 日期换了
    rqi_huanle () {
      console.log(this.value1)
      let qishi = this.value1[0]
      let jieshu = this.value1[1]
      qishi = qishi.getFullYear() + '' + this.pa(qishi.getMonth() + 1) + '' + this.pa(qishi.getDate())
      jieshu = jieshu.getFullYear() + '' + this.pa(jieshu.getMonth() + 1) + '' + this.pa(jieshu.getDate())
      this.start_sj = qishi
      this.end_sj = jieshu
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.saleb {
    height:100%;
    width:100%;
    position: relative;
    .tit{
        font-size: 0.18rem;
        color:#1A2533;
        padding: 0.28rem 0.32rem;
        border:0.01rem solid #eee;display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            font-size: 0.18rem;
            color:#1A2533;
        }
        div{
            display: flex;
            align-items: center;
            font-size: 0.14rem;
            color:#979AA1;
            cursor:pointer;
            i{
                display: block;
                height:0.25rem;
                width:0.28rem;
                background: url('../../assets/rzhi_no.png') no-repeat;
                background-position:center center;
                margin-left: 0.1rem;
            }
            &:hover i{
                background: url('../../assets/rzhi_ok.png') no-repeat;
                background-position:center center;
            }
        }
    }
    .con_tit{
        display: flex;
        justify-content: left;
        align-items: center;
        padding:0.37rem 0 0 0.51rem;
        span{
            display:block;
            height:0.36rem;
            width:0.96rem;
            border:0.01rem solid #979797;
            color:#888;
            font-size: 0.14rem;
            line-height: 0.36rem;
            text-align: center;
            &:nth-child(1){
                border-radius: 0.02rem 0 0 0.02rem;
            }
            &:nth-child(2){
                border-radius: 0 0.02rem 0.02rem 0;
            }
            margin-bottom: 0.4rem;
            cursor: pointer;
        }
        .span1{
            background:#8d52e3;
            border:0.01rem solid #8d52e3;
            color:#fff;
        }
    }
    .left_zhu{
        position: absolute;
        right:0;
        top:1.2rem;
    }
    .con{
        padding: 0 0.51rem;
        .ul1{
            .li1{
                display: flex;
                align-items: center;
                font-size: 0.14rem;
                color:#4C4A4D;
                span{
                    display: block;
                    height:0.35rem;
                    width:0.35rem;
                    margin-right: 0.18rem;
                    border:0.01rem solid #979797;
                    font-size: 0.2rem;
                    color:#888888;
                    text-align: center;
                    line-height: 0.35rem;
                    border-radius: 50%;
                }
                p{
                    font-size: 0.14rem;
                    color:#fff;
                    padding: 0.05rem 0.12rem;
                    background: #8d52e3;
                    margin-left: 0.15rem;
                    border-radius: 0.02rem;
                    cursor:pointer;
                }
                a{
                    font-size: 0.14rem;
                    color:#fff;
                    padding: 0.05rem 0.12rem;
                    background: #8d52e3;
                    margin-left: 0.15rem;
                    border-radius: 0.02rem;
                    cursor:pointer; 
                }
                .upload-demo{
                    display: flex;
                    align-items: center;
                }
            }
        }
        .ul2{
            display: flex;
            margin-left: 0.24rem;
            margin-top:0.32rem;
            margin-bottom: 0.67rem;
            .li2{
                margin-left:0.36rem;
                cursor: pointer;
                // border:0.01rem solid red;
                input{
                    -webkit-appearance: none;
                    width: 0.18rem!important;
                    height: 0.18rem!important;
                    display: inline-block;
                    margin: -0.03rem 0.05rem 0 0!important;
                    cursor: pointer;
                    vertical-align: middle;
                    background:url(../../assets/kongxin.png) no-repeat;
                    &:checked {
                        background: url(../../assets/shixin.png) no-repeat;
                    }
                    outline-color:none;
                    outline-style :none;
                    outline-width:none;
                }
                .xuanze2{
                    p{
                    float: left;
                    width:50%;
                    margin-bottom: 0.1rem;
                    }
                }
            }
        }
    }
    .box{
        // margin: 0 0.29rem;
        // height:164px;
        // background: #EEEEEE;
        // padding: 0.22rem;
        padding: 0 0.51rem;
        .ul1{
            .li1{
                display: flex;
                align-items: center;
                font-size: 0.14rem;
                color:#4C4A4D;
                span{
                    display: block;
                    height:0.35rem;
                    width:0.35rem;
                    margin-right: 0.18rem;
                    border:0.01rem solid #979797;
                    font-size: 0.2rem;
                    color:#888888;
                    text-align: center;
                    line-height: 0.35rem;
                    border-radius: 50%;
                }
                p{
                    font-size: 0.12rem;
                    color:#4C4A4D;
                    // margin-left: 0.3rem;
                    margin-top:0.9rem;
                }
            }
            .p1{
                font-size: 0.12rem;
                color: #888888;
                margin-left: 0.52rem;
                margin-top: 0.19rem;
            }
            .ul2{
                display: flex;
                margin-left: 0.18rem;
                margin-top:0.22rem;
                margin-bottom: 0.23rem;
                .li2{
                    margin-left:1.22rem;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-size: 0.12rem;
                    color:#1A2533;
                    &:nth-child(2){
                        margin-left:0.36rem;
                    }
                    img{
                        margin-right:0.08rem;
                    }
                    // input{
                    //     -webkit-appearance: none;
                    //     width: 0.18rem!important;
                    //     height: 0.18rem!important;
                    //     display: inline-block;
                    //     margin: -0.03rem 0.05rem 0 0!important;
                    //     cursor: pointer;
                    //     vertical-align: middle;
                    //     background:url(../../assets/kongxin.png) no-repeat;
                    //     &:checked {
                    //         background: url(../../assets/shixin.png) no-repeat;
                    //     }
                    //     outline-color:none;
                    //     outline-style :none;
                    //     outline-width:none;
                    // }
                }
            }
            .p2{
                font-size: 0.14rem;
                color:#fff;
                padding: 0.05rem 0.12rem;
                background: #8d52e3;
                display: inline-block;
                margin-left: 0.15rem;
                border-radius: 0.02rem;
                cursor:pointer;
                margin-left: 0.53rem;
            }
        }
        .fade-enter-active, .fade-leave-active {
          transition: opacity 1.2s
        }
        .fade-enter, .fade-leave-active {
          opacity: 0
        }
        .ul3{
            height:1.02rem;
            background: #F6F9FE;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.18rem;
            li{
                display: flex;
                align-items: center;
                // cursor: pointer;
                font-size: 0.12rem;
                color:#1A2533;
                // img{
                //     height:0.14rem;
                //     width:0.14rem;
                //     margin-right:0.08rem;
                // }
                input{
                    -webkit-appearance: none;
                    width: 0.14rem!important;
                    height: 0.14rem!important;
                    display: inline-block;
                    margin: -0.03rem 0.05rem 0 0!important;
                    cursor: pointer;
                    vertical-align: middle;
                    background:url(../../assets/kaung_kongxin.png) no-repeat;
                    &:checked {
                        background: url(../../assets/kuang_duigou.png) no-repeat;
                        background-size: 100%;
                    }
                    background-size: 100%;
                    outline-color:none;
                    outline-style :none;
                    outline-width:none;
                }
            }
        }
    }
    .shangchuan_btn{
        height:0.38rem;
        width:1.54rem;
        line-height: 0.38rem;
        border:0.01rem solid #888;
        border-radius: 0.02rem;
        text-align: center;
        margin: 0 auto;
        color:#888888;
        font-size: 0.16rem;
        cursor:pointer;
        margin-top:0.29rem;
    }
}
</style>
